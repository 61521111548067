import * as React from 'react';
import { useContext, useMemo } from 'react';

import FormContext from '../../FormContext';
import type { AsProp } from '../../types';
import FormCheckInput from './FormCheckInput';
import FormCheckLabel from './FormCheckLabel';

import { hasChildOfType, mergeClassNames } from 'src/utils/ReactUtils';

export type FormCheckType = 'checkbox' | 'radio' | 'switch';

export type FormCheckProps = {
  inline?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  type?: FormCheckType;
  isValid?: boolean;
  isInvalid?: boolean;
  ref?: React.Ref<HTMLInputElement>;
} & AsProp &
  React.InputHTMLAttributes<HTMLInputElement>;

const FormCheck: React.FC<FormCheckProps> = React.forwardRef<
  HTMLInputElement,
  FormCheckProps
>(
  (
    {
      id,
      inline = false,
      reverse = false,
      disabled = false,
      isValid = false,
      isInvalid = false,
      className,
      style,
      title = '',
      type = 'checkbox',
      label,
      children,
      as = 'input',
      ...props
    },
    ref,
  ) => {
    const { controlId } = useContext(FormContext);
    const innerFormContext = useMemo(
      () => ({
        controlId: id || controlId,
      }),
      [controlId, id],
    );

    const hasLabel =
      (!children && label != null && label !== false) ||
      hasChildOfType(children, FormCheckLabel);

    const input = (
      <FormCheckInput
        {...props}
        type={type === 'switch' ? 'checkbox' : type}
        ref={ref}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={disabled}
        as={as}
      />
    );

    return (
      <FormContext.Provider value={innerFormContext}>
        <div
          style={style}
          className={mergeClassNames([
            className,
            hasLabel && 'form-check',
            inline && `form-check-inline`,
            reverse && `form-check-reverse`,
            type === 'switch' && 'form-switch',
          ])}
        >
          {children || (
            <>
              {input}
              {hasLabel && (
                <FormCheckLabel title={title}>{label}</FormCheckLabel>
              )}
            </>
          )}
        </div>
      </FormContext.Provider>
    );
  },
);

FormCheck.displayName = 'FormCheck';

export default Object.assign(FormCheck, {
  Input: FormCheckInput,
  Label: FormCheckLabel,
});
