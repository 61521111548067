/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import type { AsProp } from '../Form/types';

import createWithPrefix from 'src/utils/createWithPrefix';
import { mergeClassNames } from 'src/utils/ReactUtils';

const InputGroupAppend = createWithPrefix('input-group-append');

const InputGroupPrepend = createWithPrefix('input-group-prepend');

const InputGroupText = createWithPrefix('input-group-text', {
  Component: 'span',
});

const InputGroupCheckbox: React.FC<any> = (props) => (
  <InputGroupText>
    <input type="checkbox" {...props} />
  </InputGroupText>
);

const InputGroupRadio: React.FC<any> = (props) => (
  <InputGroupText>
    <input type="radio" {...props} />
  </InputGroupText>
);

export type InputGroupProps = React.HTMLAttributes<HTMLElement> &
  AsProp & {
    size?: 'sm' | 'lg';
    hasValidation?: boolean;
  };

type InputGroup = InputGroupProps & {
  Append: typeof InputGroupAppend;
  Prepend: typeof InputGroupPrepend;
  Text: typeof InputGroupText;
  Checkbox: typeof InputGroupCheckbox;
  Radio: typeof InputGroupRadio;
};

const InputGroup: React.FC<InputGroupProps> = ({
  size,
  hasValidation,
  className,
  as: Component = 'div',
  ...props
}) => {
  return (
    <Component
      {...props}
      className={mergeClassNames([
        className,
        'input-group',
        size && `input-group-${size}`,
        hasValidation && 'has-validation',
      ])}
    />
  );
};

InputGroup.displayName = 'InputGroup';

export default Object.assign(InputGroup, {
  Text: InputGroupText,
  Radio: InputGroupRadio,
  Checkbox: InputGroupCheckbox,
  Append: InputGroupAppend,
  Prepend: InputGroupPrepend,
});
