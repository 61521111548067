import * as React from 'react';
import { useMemo } from 'react';

import FormContext from '../../FormContext';
import type { AsProp } from '../../types';
import styles from './Group.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type FormGroupProps = {
  controlId?: string;
  className?: string;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
} & React.HTMLAttributes<HTMLElement> &
  AsProp;

const FormGroup: React.FC<FormGroupProps> = ({
  controlId,
  as: Component = 'div',
  className,
  ...props
}) => {
  const context = useMemo(() => ({ controlId }), [controlId]);

  return (
    <FormContext.Provider value={context}>
      <Component
        {...props}
        className={mergeClassNames([className, styles.formGroup])}
      />
    </FormContext.Provider>
  );
};

export default FormGroup;
