import React from 'react';

import styles from './Modal.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
};

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  className,
  children,
  ...props
}) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget && onClose) {
      onClose();
    }
  };

  return (
    <div
      role={'dialog'}
      aria-modal={true}
      className={mergeClassNames([
        styles.modal,
        isOpen ? styles.open : '',
        className,
      ])}
      onClick={handleBackdropClick}
      {...props}
    >
      <div className={styles.dialog}>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
