import * as React from 'react';

type FormLabelProps = {
  htmlFor?: string;
  className?: string;
} & React.HTMLAttributes<HTMLLabelElement>;

const FormLabel: React.FC<FormLabelProps> = ({
  className,
  htmlFor,
  ...props
}) => {
  htmlFor = htmlFor;

  return <label className={className} htmlFor={htmlFor} {...props} />;
};

FormLabel.displayName = 'FormLabel';

export default FormLabel;
