import type { FormEvent, FunctionComponent } from 'react';
import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';

import { Col, Container, Row } from 'src/components/ui';
import Form from 'src/components/ui/Form';
import InputGroup from 'src/components/ui/InputGroup';

import {
  getDataTestId,
  mergeClassNames,
  usePageType,
} from '../../../utils/ReactUtils';
import Title3 from '../Title/Title3/Title3';
import style from './Newsletter.module.scss';
import { NewsletterModal } from './NewsletterModal/NewsletterModal';

import { isRequired, isInvalidFormat } from 'src/utils/ValidationMessage.Utils';

import { ButtonSecondary } from '..';

export type NewsletterProps = {
  isOnSideBar?: boolean;
  dataTestId?: string;
};

const Newsletter: FunctionComponent<NewsletterProps> = ({
  dataTestId = 'news_section',
  isOnSideBar,
}) => {
  const [emailValue, setEmailValue] = useState('');
  const [validated, setValidated] = useState(false);
  const [openNewsletterFormModal, setOpenNewsletterFormModal] = useState(false);
  const pageType = usePageType();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setOpenNewsletterFormModal(true);
  };

  const gtmEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'newsletter',
        eventAction: 'CTA je mabonne newsletter',
        eventLabel: '/',
        pageType,
      },
    });
  };

  return (
    <>
      <section
        className={mergeClassNames([
          style.newsletter,
          isOnSideBar ? style.newsletterForSideBar : '',
        ])}
        {...getDataTestId({ dataTestId })}
      >
        <Container>
          <Title3 className={style.newsletterTitle}>
            <p>Inscrivez-vous à notre newsletter</p>
          </Title3>
          {!isOnSideBar && (
            <p className={style.newsletterDescription}>
              Et recevez nos offres et conseils exclusifs
            </p>
          )}

          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className={style.newsletterInlineForm}
          >
            <Row className="justify-content-center">
              <Col className={style.newsletterInlineFormCol} xs="8" md="4">
                <Form.Group
                  className={style.newsletterInlineFormGroup}
                  controlId="validationEmail"
                >
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      placeholder="Saisissez votre email"
                      className={style.newsletterInlineFormInput}
                      required
                      value={emailValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmailValue(e.target.value)
                      }
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={style.newsletterInlineFormValidationError}
                    >
                      {emailValue.length
                        ? isInvalidFormat('Email')
                        : isRequired('Email')}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <ButtonSecondary
                type="submit"
                className={style.newsletterInlineFormBtn}
                color={'Brown'}
                onClick={gtmEvent}
              >
                {"Je m'abonne"}
              </ButtonSecondary>
            </Row>
          </Form>
        </Container>
      </section>
      {openNewsletterFormModal ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RE_CAPTCHA_V3_ID ?? ''}
        >
          <NewsletterModal
            isShow={openNewsletterFormModal}
            onHide={() => setOpenNewsletterFormModal(false)}
            initialEmail={emailValue}
          />
        </GoogleReCaptchaProvider>
      ) : null}
    </>
  );
};
export default Newsletter;
