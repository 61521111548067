import Head from 'next/head';
import type { FunctionComponent } from 'react';
import React from 'react';

type MetaDescriptionProps = {
  description: string;
};

const MetaDescription: FunctionComponent<MetaDescriptionProps> = ({
  description,
}) => {
  return (
    <Head>
      <meta name="description" content={description} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description}
      />
    </Head>
  );
};

export default MetaDescription;
