import Check from './components/Check';
import Control from './components/Control';
import Group from './components/Group';
import Label from './components/Label';

import createWithPrefix from 'src/utils/createWithPrefix';
import { mergeClassNames } from 'src/utils/ReactUtils';

const Row = createWithPrefix('form-row');

export type FormProps = {
  validated?: boolean;
} & React.FormHTMLAttributes<HTMLFormElement>;

const Form: React.FC<FormProps> = ({ className, validated, ...props }) => (
  <form
    {...props}
    className={mergeClassNames([className, validated && 'was-validated'])}
  />
);

export default Object.assign(Form, {
  Group,
  Row,
  Control,
  Check,
  Label,
});
