import * as React from 'react';
import { useContext } from 'react';

import FormContext from '../../FormContext';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type FormCheckLabelProps = {
  className?: string;
  ref?: React.Ref<HTMLLabelElement>;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const FormCheckLabel: React.FC<FormCheckLabelProps> = React.forwardRef(
  ({ className, htmlFor, ...props }, ref) => {
    const { controlId } = useContext(FormContext);

    return (
      <label
        {...props}
        ref={ref}
        htmlFor={htmlFor || controlId}
        className={mergeClassNames([className, 'form-check-label'])}
      />
    );
  },
);

FormCheckLabel.displayName = 'FormCheckLabel';

export default FormCheckLabel;
