import * as React from 'react';

import type { AsProp } from '../../types';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type FeedbackType = 'valid' | 'invalid';

export type FeedbackProps = {
  type?: FeedbackType;
} & AsProp &
  React.HTMLAttributes<HTMLElement>;

const Feedback = React.forwardRef<'div', FeedbackProps>(
  ({ as: Component = 'div', className, type = 'valid', ...props }, ref) => (
    <Component
      {...props}
      ref={ref}
      className={mergeClassNames([className, `${type}-feedback`])}
    />
  ),
);

Feedback.displayName = 'Feedback';

export default Feedback;
