import * as React from 'react';
import { useContext } from 'react';

import FormContext from '../../FormContext';
import type { AsProp } from '../../types';

import { mergeClassNames } from 'src/utils/ReactUtils';

type FormCheckInputType = 'checkbox' | 'radio';

export type FormCheckInputProps = {
  type?: FormCheckInputType;
  isValid?: boolean;
  isInvalid?: boolean;
  ref?: React.Ref<HTMLInputElement>;
} & AsProp &
  React.InputHTMLAttributes<HTMLInputElement>;

const FormCheckInput: React.FC<FormCheckInputProps> = React.forwardRef<
  HTMLInputElement,
  FormCheckInputProps
>(
  (
    {
      id,
      className,
      type = 'checkbox',
      isValid = false,
      isInvalid = false,
      as: Component = 'input',
      ...props
    },
    ref,
  ) => {
    const { controlId } = useContext(FormContext);

    return (
      <Component
        {...props}
        ref={ref}
        type={type}
        id={id || controlId}
        className={mergeClassNames([
          className,
          'form-check-input',
          isValid && 'is-valid',
          isInvalid && 'is-invalid',
        ])}
      />
    );
  },
);

FormCheckInput.displayName = 'FormCheckInput';

export default FormCheckInput;
