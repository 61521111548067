import type { NewsletterFormModel, NewsletterResponseModel } from 'src/models';

import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export const sendDataToNewsletterApi = async (
  newsletterFormFieldData: NewsletterFormModel,
) => {
  return performServerAPICall<NewsletterResponseModel>({
    url: `newsletters`,
    method: 'post',
    data: newsletterFormFieldData,
  });
};
