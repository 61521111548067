/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelize } from 'humps';
import React from 'react';

import { mergeClassNames } from './ReactUtils';

const pascalCase = (str: string) =>
  str[0].toUpperCase() + camelize(str).slice(1);

type CreateWithPrefixProps = {
  displayName?: string;
  Component?: string;
};

const createWithPrefix = (
  prefix: string,
  { displayName = pascalCase(prefix), Component }: CreateWithPrefixProps = {},
) => {
  const PrefixedComponent = React.forwardRef(
    ({ className, as: Tag = Component || 'div', ...props }: any, ref) => {
      return (
        <Tag
          ref={ref}
          className={mergeClassNames([className, prefix])}
          {...props}
        />
      );
    },
  );
  PrefixedComponent.displayName = displayName;

  return PrefixedComponent as any;
};

export default createWithPrefix;
