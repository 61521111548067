import * as React from 'react';
import { useContext } from 'react';

import FormContext from '../../FormContext';
import type { AsProp } from '../../types';
import Feedback from '../Feedback';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

export type FormControlProps = {
  htmlSize?: number;
  size?: 'sm' | 'lg';
  plaintext?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string | string[] | number;
  onChange?: React.ChangeEventHandler<FormControlElement>;
  type?: string;
  custom?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  required?: boolean;
  name?: string;
  rows?: number;
  min?: number;
  max?: number;
  step?: number;
  pattern?: string;
  ref?: React.Ref<FormControlElement>;
} & React.HTMLAttributes<FormControlElement> &
  AsProp;

const FormControl: React.FC<FormControlProps> = React.forwardRef(
  (
    {
      type,
      size,
      htmlSize,
      id,
      className,
      isValid = false,
      isInvalid = false,
      custom,
      plaintext,
      readOnly,
      as: Component = 'input',
      required,
      ...props
    },
    ref,
  ) => {
    const { controlId } = useContext(FormContext);
    let classes = [];

    if (plaintext) {
      classes = [`plaintext`];
    } else if (type === 'file') {
      classes = [`file`];
    } else if (type === 'range') {
      classes = [`range`];
    } else if (Component === 'select' && custom) {
      classes = [`select`, `select-${size}`];
    } else {
      classes = ['form-control', `${size}`];
    }

    return (
      <Component
        {...props}
        type={type}
        size={htmlSize}
        readOnly={readOnly}
        id={id || controlId}
        required={required}
        ref={ref}
        className={mergeClassNames([
          className,
          ...classes,
          size && size,
          isValid && 'is-valid',
          isInvalid && 'is-invalid',
        ])}
      />
    );
  },
);

FormControl.displayName = 'FormControl';

export default Object.assign(FormControl, {
  Feedback,
});
